
import { defineComponent } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import Toast from "@/common/ts/Toast.js";
import { AxiosRequestConfig } from "node_modules/axios";
import Bus from "@/common/ts/Bus";
import store from "@/store";

export default defineComponent({
  name: "account-settings",
  data() {
    return {
      ruleForm: {},
      rules: {
        user_name: [
          {
            required: true,
            message: "请输入昵称",
            trigger: "blur",
          },
        ],
        // gender: [
        //   {
        //     required: true,
        //     message: "请选择性别",
        //     trigger: "blur",
        //   },
        // ],
        // birthday: [
        //   {
        //     required: true,
        //     message: "请选择日期",
        //     trigger: "blur",
        //   },
        // ],
      },
    };
  },
  methods: {
    selectDate(val) {
      (this.ruleForm as any).birthday =
        val.getFullYear() +
        "-" +
        (val.getMonth() + 1 >= 10
          ? val.getMonth() + 1
          : "0" + (val.getMonth() + 1)) +
        "-" +
        (val.getDate() >= 10 ? val.getDate() : "0" + val.getDate());
    },
    submitForm() {
      (this.$refs["ruleForm"] as any).validate((valid) => {
        if (valid) {
          // Activate indicator
          (this.$refs["submitButton"] as any).setAttribute(
            "data-kt-indicator",
            "on"
          );

          ApiService.post(
            "MemberProfile/ProfileDetailEdit",
            this.ruleForm as AxiosRequestConfig
          )
            .then(({ data }) => {
              if (data.success == true) {
                Bus.$emit("getUserDetail");
                Toast.success(data.message);
              } else {
                Toast.warning(data.message);
              }
            })
            .catch((error) => {
              Toast.error(error.message);
            })
            .finally(() => {
              (this.$refs["submitButton"] as any).removeAttribute(
                "data-kt-indicator"
              );
            });
        }
      });
    },
  },
  mounted() {
    setCurrentPageBreadcrumbs("资料设置", ["账户"]);
    const user = store.getters.currentUser;
    (this.ruleForm as any).user_name = user.user_name;
    (this.ruleForm as any).gender = user.gender;
    (this.ruleForm as any).birthday = user.birthday;
  },
});
