import Swal from "sweetalert2/dist/sweetalert2.min.js";

function success(title, second) {
  var timer = 1500;
  if(second)
  {
    timer = second
  }
  Swal.fire({
    icon: "success",
    position: "top",
    title: title,
    showConfirmButton: false,
    timer: timer,
  });
}

function info(title) {
  Swal.fire({
    icon: "info",
    position: "top",
    title: title,
    showConfirmButton: false,
    timer: 1500,
  });
}

function warning(title) {
  Swal.fire({
    icon: "warning",
    position: "top",
    title: title,
    showConfirmButton: false,
    timer: 1500,
  });
}

function error(title) {
  Swal.fire({
    icon: "error",
    position: "top",
    title: title,
    showConfirmButton: false,
    timer: 1500,
  });
}

function confirm(message, callback) {
  Swal.fire({
    title: "",
    text: message,
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#DD6B55",
    confirmButtonText: "确定",
    cancelButtonText: "取消",
  }).then((result) => {
    if (result.isConfirmed) {
      if (callback) {
        callback();
      }
    }
  });
}

function show(message, callback) {
  Swal.fire({
    title: "",
    text: message,
    icon: "success",
    showCancelButton: false,
    confirmButtonText: "确定"
  }).then((result) => {
    if (result.isConfirmed) {
      if (callback) {
        callback();
      }
    }
  });
}


export default {
  success,
  info,
  warning,
  error,
  confirm,
  show
};
